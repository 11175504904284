import { useState, useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import Button from '@components/ui/button'

export const DealsOfTheDay = ({ validity }) => {
  const { t: translate } = useTranslation('home')
  const unitMapping = {
    days: 'Days',
    hours: 'Hr',
    minutes: 'Mins',
    seconds: 'Sec',
  }

  const { days, hours, minutes, seconds } = validity

  const [timeLeft, setTimeLeft] = useState({
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  })

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (
          prevTime.days === 0 &&
          prevTime.hours === 0 &&
          prevTime.minutes === 0 &&
          prevTime.seconds === 0
        ) {
          clearInterval(timer)
          return prevTime
        }

        let newTime = { ...prevTime }
        if (newTime.seconds > 0) {
          newTime.seconds--
        } else {
          newTime.seconds = 59
          if (newTime.minutes > 0) {
            newTime.minutes--
          } else {
            newTime.minutes = 59
            if (newTime.hours > 0) {
              newTime.hours--
            } else {
              newTime.hours = 23
              newTime.days--
            }
          }
        }
        return newTime
      })
    }, 1000)

    return () => clearInterval(timer)
  }, [])

  return (
    <div className="max-w-md p-6 rounded-lg">
      <h2 className="text-[40px] font-bold mb-2 text-brand-grayfacet">
        Deals Of The Day
      </h2>
      <p className="text-base text-grey-300 mt-5 mb-4 font-normal font-poppins">
        {translate('deals-of-the-day-subheading')}
      </p>
      <Link href="/deals">
        <Button className="bg-brand-darkblue text-white text-base !font-normal mt-10 mb-12 px-4 py-2 rounded-md w-[207px]">
          {translate('text-shop-all-deals')}
        </Button>
      </Link>
      <h3 className="font-poppins font-medium text-[28px] text-[#484848] mb-4">
        Hurry, Before It&apos;s Too Late!
      </h3>
      <div className="flex justify-between">
        {Object.entries(timeLeft).map(([unit, value]) => (
          <div key={unit} className="text-center">
            <div className="bg-white rounded-md p-2 w-[76px] h-[76px] mb-1 w-16 shadow-[0px_4px_14px_1px_#00000029] flex items-center rounded-[10px]">
              <span className="text-[32px] font-normal font-digital-numbers text-[#484848] inline-block w-full text-center leading-none">
                {value.toString().padStart(2, '0')}
              </span>
            </div>
            <span className="font-poppins font-normal text-[24px] text-[#484848] capitalize">
              {unitMapping[unit]}
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}
