import { useState, useEffect, useRef, useCallback } from 'react'
import ProductCardNew from '@components/product/product-cards/product-card-new'
import { useFeaturedProductsQuery } from '@framework/product/get-all-featured-products'
import { LIMITS } from '@framework/utils/limits'

export const DealsOfTheDayProducts = () => {
  const { data } = useFeaturedProductsQuery({
    limit: LIMITS.BEST_SELLER_PRODUCTS_LIMITS,
  })

  const containerRef = useRef(null)
  const paginationDotsRef = useRef(null)

  const [products, setProducts] = useState([])
  const [totalPages, setTotalPages] = useState(0)
  const [productsPerPage, setProductsPerPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(0)

  useEffect(() => {
    if (data) {
      const newProductsPerPage = window.innerWidth < 768 ? 1 : 3
      setProducts(data)
      setProductsPerPage(newProductsPerPage)
      setTotalPages(Math.ceil(data.length / newProductsPerPage))
    }
  }, [data])

  const updatePaginationDots = useCallback(() => {
    if (
      !containerRef.current ||
      !paginationDotsRef.current ||
      products.length === 0
    )
      return

    const isMobile = window.innerWidth < 768
    const itemsPerPage = isMobile ? 1 : 3

    const totalWidth = containerRef.current.scrollWidth
    const viewWidth = containerRef.current.clientWidth

    const scrollLeft = containerRef.current.scrollLeft
    let pageIndex = Math.round(scrollLeft / viewWidth)

    if (scrollLeft + viewWidth >= totalWidth - 1) {
      pageIndex = totalPages - 1 // Force set last page index
    }

    setCurrentPage(pageIndex)

    const dots = paginationDotsRef.current.querySelectorAll('button')
    dots.forEach((dot, index) => {
      dot.classList.toggle('bg-gray-900', index === pageIndex)
      dot.classList.toggle('bg-gray-300', index !== pageIndex)
    })
  }, [products.length, totalPages])

  const scrollToPage = (pageIndex) => {
    if (!containerRef.current || products.length === 0) return

    const isMobile = window.innerWidth < 768
    const itemsPerPage = isMobile ? 1 : 3

    const productWidth = containerRef.current.scrollWidth / products.length

    const scrollPosition = productWidth * pageIndex * itemsPerPage

    containerRef.current.scrollTo({
      left: scrollPosition,
      behavior: 'smooth',
    })

    setCurrentPage(pageIndex)
  }

  useEffect(() => {
    const handleResize = () => {
      const newProductsPerPage = window.innerWidth < 768 ? 1 : 3
      setProductsPerPage(newProductsPerPage)
      setTotalPages(Math.ceil(products.length / newProductsPerPage))
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [products])

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener('scroll', updatePaginationDots)
    }
    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('scroll', updatePaginationDots)
      }
    }
  }, [updatePaginationDots])

  useEffect(() => {
    if (containerRef.current) {
      const initialPage = Math.round(
        containerRef.current.scrollLeft / containerRef.current.clientWidth,
      )
      setCurrentPage(initialPage)
      updatePaginationDots()
    }
  }, [totalPages])

  return (
    <div className="max-w-xs sm:max-w-sm md:max-w-2xl lg:max-w-2xl xl:max-w-4xl 2xl:max-w-6xl mx-auto relative w-full overflow-hidden">
      <div className="overflow-hidden">
        {/* Product Carousel */}
        <div
          ref={containerRef}
          className="flex gap-4 overflow-x-auto snap-x snap-mandatory scroll-smooth no-scrollbar w-full"
        >
          {products.map((product, idx) => (
            <div
              key={idx}
              className="relative flex-shrink-0 w-full md:w-[calc((100%_-_2rem)/3)] borobazar-alpine-product-card rounded-[10px] bg-white transition-all hover:shadow-product-hover"
            >
              <ProductCardNew product={product} />
            </div>
          ))}
        </div>

        {/* Pagination Dots */}
        <div
          ref={paginationDotsRef}
          className="pagination-dots flex justify-center mt-8 space-x-2"
        >
          {Array.from({ length: totalPages }).map((_, index) => (
            <button
              key={index}
              className={`w-3 h-3 rounded-full ${
                currentPage === index ? 'bg-gray-900' : 'bg-gray-300'
              }`}
              onClick={() => scrollToPage(index)}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
