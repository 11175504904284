import { QueryOptionsType, Product } from '@framework/types'
import { useQuery } from 'react-query'
import * as typesense from '../typesense'
import { useRouter } from 'next/router'

export const fetchDirectDealProducts = async ({ queryKey }: any) => {
  const [_key, _params, locale] = queryKey
  const { hits }: any = await typesense.getDirectDealProducts(locale)
  const products: Product[] = []
  hits.map((h: any) => {
    if (h.document.name) {
      products.push(h.document)
    }
  })

  return products as Product[]
}

export const useDirectDealProductsQuery = (options: QueryOptionsType) => {
  return useQuery<Product[], Error>(
    ['directDealProducts', options, useRouter().locale],
    fetchDirectDealProducts,
  )
}
