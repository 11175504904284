import { QueryOptionsType, Product } from '@framework/types'
import { useQuery } from 'react-query'
import * as typesense from '../typesense'
import { useRouter } from 'next/router'

export const fetchNewArrivalProducts = async ({ queryKey }: any) => {
  const [_key, _params, locale] = queryKey
  const { hits }: any = await typesense.getNewArrivalProducts(locale)
  const products: Product[] = []
  hits.map((h: any) => {
    if (h.document.name) {
      products.push(h.document)
    }
  })

  return products as Product[]
}

export const useNewArrivalProductsQuery = (options: QueryOptionsType) => {
  return useQuery<Product[], Error>(
    ['newArrivalProducts', options, useRouter().locale],
    fetchNewArrivalProducts,
  )
}
