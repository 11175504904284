import Image from '@components/ui/image'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useIsUSLocale } from '@utils/helper'
import { INSTAGRAM_URLS } from '@constants/index'

export const FollowSocialMediaBar = () => {
  const { t: translate } = useTranslation('home')
  const isUS = useIsUSLocale()

  const homeAssetsPath = '/assets/images/home-one'
  return (
    <>
      <div className="!px-[18px] mb-4 md:mb-12 sm:mt-20 lg:mt-0 lg:mb-12">
        <h1 className="text-[29px] md:text-[46px] font-bold text-brand-grayfacet !mb-6 text-center">
          {translate('social-media')}
        </h1>
        <h2 className="text-[#8A8A8A] mb-4 lg:!mb-10 text-center max-w-2xl mx-auto font-['Poppins'] text-[16px] font-normal">
          {translate('social-media-subheading')}
        </h2>
      </div>
      <div className="flex items-center justify-center max-w-[1913px] max-h-[388px] mx-auto font-poppins origin-[center_top]">
        <div className="hidden min-w-[1024px] min-h-[200px] sm:w-[1913px] xs:h-[70px]-- sm:h-[270px]-- lg:h-[388px] aspect-[16/9] relative overflow-hidden lg:block">
          <Link
            href={isUS ? INSTAGRAM_URLS.US : INSTAGRAM_URLS.CA}
            target="_blank"
          >
            <Image
              src={`${homeAssetsPath}/${
                isUS
                  ? 'follow-us-social-media.svg'
                  : 'Social-Media-Banner-1913x388.png'
              }`}
              alt={'Banner Image'}
              fill={true}
              quality={100}
              priority
              className="object-contain"
            />
          </Link>
        </div>
        <div className="flex min-w-[375px] xsp:min-w-[432px] min-h-[221px] relative overflow-hidden xs:block lg:!hidden aspect-[16/9]">
          <Link
            href={isUS ? INSTAGRAM_URLS.US : INSTAGRAM_URLS.CA}
            target="_blank"
          >
            <Image
              src={`${homeAssetsPath}/${
                isUS
                  ? 'follow-us-social-media-mobile.svg'
                  : 'Social-Media-Banner-432x221.png'
              }`}
              alt={'Banner Image'}
              fill={true}
              quality={100}
              priority
              className="object-contain sm:object-cover--"
            />
          </Link>
        </div>
      </div>
    </>
  )
}
