import { QueryOptionsType, Product } from '@framework/types'
import { useQuery } from 'react-query'
import * as typesense from '../typesense'
import { useRouter } from 'next/router'

export const fetchLiquidationProducts = async ({ queryKey }: any) => {
  const [_key, _params, locale] = queryKey
  const { hits }: any = await typesense.getLiquidationProducts(locale)
  const products: Product[] = []
  hits.map((h: any) => {
    if (h.document.name) {
      products.push(h.document)
    }
  })

  return products as Product[]
}

export const useLiquidationProductsQuery = (options: QueryOptionsType) => {
  return useQuery<Product[], Error>(
    ['liquidationProducts', options, useRouter().locale],
    fetchLiquidationProducts,
  )
}
