import { useSessionStorage } from 'react-use'
import Link from '@components/ui/link'
import HighlightedBar from '@components/common/highlighted-bar'
import HeaderUS from '@components/layout/header/header-new_US'
import HeaderCA from '@components/layout/header/header-new_CA'
import Footer from '@components/layout/footer/home-footer'
import MobileNavigation from '@components/layout/mobile-navigation/mobile-navigation'
import { IoChevronForwardCircleOutline } from 'react-icons/io5'
import { useTranslation } from 'next-i18next'
import { useIsMounted } from '@utils/use-is-mounted'
import { memo, PropsWithChildren, useEffect, useState } from 'react'
import { Drawer } from '@components/common/drawer/drawer'
import { getDirection } from '@utils/get-direction'
import { useRouter } from 'next/router'
import { useUI } from '@contexts/ui.context'
import motionProps from '@components/common/drawer/motion'
import dynamic from 'next/dynamic'
import { useSession } from 'next-auth/react'
import { getAllManufacturersData } from '@framework/magento'
import { ROUTES } from '@utils/routes'
import { getNameSlug } from '@utils/helper'
import ScrollToTopButton from '@components/home-one/ScrollToTopButton'

function ClientRenderedHightLightedBar() {
  const { t } = useTranslation('common')
  const [highlightedBar, setHighlightedBar] = useSessionStorage(
    'borobazar-highlightedBar',
    'false',
  )
  return (
    <>
      {highlightedBar !== 'true' &&
        false && ( // @TODO remove TEMP "false"
          <HighlightedBar
            onClose={() => setHighlightedBar('true')}
            className="text-brand-light]"
          >
            <div className="text-sm font-medium py-0.5 ltr:pr-6 rtl:pl-6">
              <span>
                {t(
                  '35% Exclusive discount plus free next day delivery, excludes sale',
                )}
                <Link
                  href="#"
                  className="opacity-80 inline-flex text-xs uppercase font-bold ltr:pl-1.5 rtl:pr-1.5 items-center relative transition-all hover:opacity-100"
                >
                  <span className="border-b border-brand-light inline-block pb-0.5">
                    Learn More
                  </span>
                  <IoChevronForwardCircleOutline className="text-xl ltr:ml-1 rtl:mr-1 relative -top-0.5" />
                </Link>
              </span>
            </div>
          </HighlightedBar>
        )}
    </>
  )
}
const MobileMenu = dynamic(
  () => import('@components/layout/header/mobile-menu'),
)

export default memo(function Layout({ children }: PropsWithChildren<{}>) {
  const isMounted = useIsMounted()
  const { closeSidebar, displaySidebar } = useUI()
  const { locale } = useRouter()
  const dir = getDirection(locale)
  const contentWrapperCSS = dir === 'ltr' ? { left: 0 } : { right: 0 }
  const comingSoonStatus = process.env.NEXT_PUBLIC_COMING_SOON_STATUS
  const countryCode = process.env.NEXT_PUBLIC_WEBSITE_COUNTRY || 'US'

  return (
    <div className="flex flex-col min-h-screen layout-six-wrap">
      {isMounted && <ClientRenderedHightLightedBar />}
      {/* End of highlighted bar  */}

      {comingSoonStatus !== 'enabled' &&
        (countryCode === 'CA' ? <HeaderCA /> : <HeaderUS />)}
      <main
        className="relative flex-grow"
        style={{
          WebkitOverflowScrolling: 'touch',
        }}
      >
        {children}
      </main>
      {comingSoonStatus !== 'enabled' && <Footer />}
      {comingSoonStatus !== 'enabled' && (
        <Drawer
          className="w-[375px]"
          placement={dir === 'rtl' ? 'right' : 'left'}
          open={displaySidebar}
          onClose={closeSidebar}
          // @ts-ignore
          level={null}
          contentWrapperStyle={contentWrapperCSS}
          {...motionProps}
        >
          <MobileMenu />
        </Drawer>
      )}
      <div>
        <ScrollToTopButton />
      </div>
    </div>
  )
})
